<template>
  <div class="admin-login">
    <el-form ref="loginForm" :model="loginForm" :rules="rules" class="login-box">
      <div class="title">大嘴鸟AI管理后台</div>
      <el-form-item prop="phone">
        <el-input type="text" v-model="loginForm.phone" placeholder="账号" />
      </el-form-item>
      <el-form-item prop="password" class="form-item">
        <el-input type="password" v-model="loginForm.password" placeholder="请输入密码" show-password />
      </el-form-item>
      <el-checkbox v-model="loginForm.remember">记住账号和密码</el-checkbox>
      <el-form-item class="form-item">
        <el-button type="primary" @click="doLogin">登录</el-button>
      </el-form-item>
      <div class="bottom-name">
        <span>2024 - 大嘴鸟AI管理后台.</span>
      </div>
    </el-form>
  </div>
</template>

<script>
import { aiAdminLogin } from '../../request/api'
import { mapMutations } from 'vuex'

export default {
  data() {
    return {
      loginForm: {
        phone: '',
        password: '',
        remember: false
      },
      rules: {
        phone: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async doLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          try {
            const res = await aiAdminLogin(this.loginForm)
            if (res.status !== 200 || res.data.code !== '1000') {
              this.$message.error(res.data.msg)
              return
            }
            this.setUser(res.data.data)
            localStorage.setItem('usersinfomation', JSON.stringify(res.data.data))
            this.$router.push('/AiHome')
          } catch (error) {
            this.$message.error('登录请求失败');
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    ...mapMutations('m_login', ['setUser'])
  }
}
</script>

<style lang="less" scoped>
.admin-login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  .login-box {
    width: 340px;
    text-align: center;
    .form-item {
      margin-bottom: 6px;
    }
    .el-checkbox {
      text-align: left;
      width: 100%;
    }
    .title {
      color: #6c6c6c;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .el-checkbox {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .el-button {
      width: 100%;
    }
    .bottom-name {
      font-size: 12px;
      margin-top: 4px;
      line-height: 14px;
    }
  }
}
</style>